import React, { useEffect } from "react";
import { connect } from "react-redux";
import * as actionCreators from "../../store/actions";
import { Button, Input } from "../../components/Ui";
import styled from "styled-components";
import { respondTo } from "../../theme/mixin";
import useForm from "../../hooks/useForm";
import { ChevronRight } from "@styled-icons/boxicons-regular";
import { profileform } from "../../formsConfig/formsConfig";

const ProfileContainer = styled.div`
  .box-body {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    &.is-edit {
      .field-group,
      .field-group-half {
        margin-bottom: 0;
      }
    }

    .field-group-half {
      width: calc(50% - 10px);
      margin-bottom: 30px;
    }

    .field-group {
      width: 100%;
      margin-bottom: 30px;
    }

    span {
      display: block;
      color: #fdca46;
      font-weight: 700;
    }

    strong {
      font-size: 16px;
      color: #fff;
    }
  }

  .box-footer {
    margin-top: 30px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
  }
`;

const Profile = (props) => {
  const { editUser, userInfo, updateModal, closeModalEdit, closed } = props;

  /* useEffect(() => {
    if (updateModal) {
      props.confirmed();
      closeModalEdit();
    }
  }, [updateModal]); */

  useEffect(() => {
    if (updateModal) {
      props.confirmed();
    }
  }, [updateModal]);

  const profileForm = profileform(
    userInfo && userInfo.shipment_firstname,
    userInfo && userInfo.shipment_lastname,
    userInfo && userInfo.address1,
    userInfo && userInfo.city,
    (userInfo && userInfo.district) || "",
    userInfo && userInfo.zipcode,
    userInfo && userInfo.address2
  );

  const updateProfile = () => {
    let editData = {
      shipment_firstname: formData.shipment_firstname.value,
      shipment_lastname: formData.shipment_lastname.value,
      address1: formData.address1.value,
      city: formData.city.value,
      district: formData.district.value,
      zipcode: formData.zipcode.value,
      address2: formData.address2.value,
    };
    editUser(editData);
  };

  let datePickerNames = [];

  for (var prop in profileForm) {
    if (profileForm[prop].elementType === "datepicker") {
      datePickerNames.push(profileForm[prop].elementConfig.name);
    }
  }

  const {
    inputChangedHandler,
    formData,
    formSubmitHandler,
    firstSubmit,
    showError,
    startDate,
    datePickerHandler,
    fileUploadHandler,
    deleteFileHandler,
    imgPreviewUrl,
    districts,
    cities,
    zipcode,
    onSetFormValid,
    resetFormHandler,
  } = useForm(updateProfile, profileForm, datePickerNames);

  let inputArr = [];
  for (let key in formData) {
    inputArr.push({ name: key, ...formData[key] });
  }
  let inputs = inputArr.map((inp, indx) => {
    return (
      <Input
        error={inp.errorMessage}
        key={inp.name}
        value={inp.value}
        elementType={inp.elementType}
        elementConfig={inp.elementConfig}
        changed={inputChangedHandler}
        label={inp.label}
        isvalid={inp.valid}
        istouched={inp.validation.touched}
        showError={showError}
        firstSubmit={firstSubmit}
        startdate={startDate}
        changedDatePicker={datePickerHandler}
        fileUploadHandler={fileUploadHandler}
        deleteFileHandler={deleteFileHandler}
        imagePreviewUrl={imgPreviewUrl}
        inModal={true}
        radioInputs={inp.inputs}
        districts={districts}
        cities={cities}
        zipcode={zipcode}
        onSetFormValid={onSetFormValid}
        resetFormHandler={resetFormHandler}
        {...inp}
      />
    );
  });

  return (
    <ProfileContainer>
      <div className="box-body is-edit">
        <div className="field-group-half">{inputs[0]}</div>
        <div className="field-group-half">{inputs[1]}</div>
        <div className="field-group">{inputs[2]}</div>
        <div className="field-group-half">{inputs[4]}</div>
        <div className="field-group-half">{inputs[5]}</div>
        <div className="field-group">{inputs[3]}</div>
        <div className="field-group">{inputs[6]}</div>
      </div>

      <div className="box-footer">
        <Button
          gradient={{ left: "#ADB1BC", right: "#ADB1BC" }}
          upper
          onClick={closed}
        >
          Annulla
        </Button>
        <Button
          gradient={{ left: "#E61274", right: "#FF7DB9" }}
          upper
          onClick={(event) => {
            formSubmitHandler(event);
          }}
        >
          Completa
          <ChevronRight size={30} />
        </Button>
      </div>
    </ProfileContainer>
  );
};

const mapStateToProps = (state) => {
  return {
    userLogged: state.user.userLogged,
    userInfo: state.user.user.userinfo,
    loading: state.user.loading,
    codes: state.user.user.codes,
    updateModal: state.user.updateModal,
    updateError: state.user.user.updateError,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    closeModalEdit: () => dispatch(actionCreators.closeModalEdit()),
    editUser: (data) => dispatch(actionCreators.editUser(data)),
    getUserIsLogged: () => dispatch(actionCreators.getUserIsLogged()),
    logout: () => dispatch(actionCreators.logout()),
    toggleSidebar: () => dispatch(actionCreators.toggleSidebar()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
